export const genres = {
    Collapsed: true,
    Container: true,
    DAG: true,
    HTTP: true,
    Pod: true,
    Retry: true,
    Skipped: true,
    StepGroup: false,
    Steps: true,
    Suspend: true,
    TaskGroup: false
};
